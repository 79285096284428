import React from 'react';
import Main from '../containers/Layout';
import productInfoContent from '../../content/pages/product-information-management.yml';
import ProductInformationManagementContainer from '@/containers/toolkit/ProductInformationManagementContainer';

export interface ProductInformationManagementPageContent {
  viewport1: {
    title1: string;
    title2: string;
    title3: string;
    description: string;
    button: {
      label: string;
      url: string;
    };
  };
  viewport2: {
    title: string;
    carouselItems: {
      title: string;
      description: string;
    }[];
  };
  viewport3: {
    sectionTitle: string;
    sectionDescription: string;
    integrationsTitle: string;
    integrationsItems: {
      image: string;
    }[];
    otherIntegrationsCount: string;
  };
  viewport4: {
    cardLabel: string;
    cardText: string;
    button: {
      label: string;
      url: string;
    };
  };
  viewport5: {
    title: string;
    accentText: string;
    description1: string;
    description2: string;
  };
  viewport6: {
    title: string;
    leftColumn: { title: string; description: string }[];
    rightColumn: { title: string; description: string }[];
    bottomText: string;
    button: { label: string; url: string };
  };
}

const ProductInformationManagement: React.FunctionComponent = () => {
  const { viewport1, viewport2, viewport3, viewport4, viewport5, viewport6 } =
    productInfoContent as unknown as ProductInformationManagementPageContent;
  return (
    <Main>
      <ProductInformationManagementContainer
        content={{
          viewport1,
          viewport2,
          viewport3,
          viewport4,
          viewport5,
          viewport6,
        }}
      />
    </Main>
  );
};

export default ProductInformationManagement;
